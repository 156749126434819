import { createApp } from "vue";
import "./style.css";
import "./modal.css";
import App from "./App.vue";
import { createGtm } from "@gtm-support/vue-gtm";
import { createPinia } from "pinia";
import piniaPluginPersistedstate from "pinia-plugin-persistedstate";
import router from "./routers";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import axios from "axios";

// Create async initialization function
async function initializeApp() {
  const app = createApp(App);

  // Define measurement IDs
  const GA4_MEASUREMENT_ID = 'G-0J44QSWQV4';
  const ADS_MEASUREMENT_ID = 'AW-16523079416';

  // Add GA4 script
  const ga4Script = document.createElement('script');
  ga4Script.async = true;
  ga4Script.src = `https://www.googletagmanager.com/gtag/js?id=${GA4_MEASUREMENT_ID}`;
  document.head.appendChild(ga4Script);

  // Add Google Ads script
  const adsScript = document.createElement('script');
  adsScript.async = true;
  adsScript.src = `https://www.googletagmanager.com/gtag/js?id=${ADS_MEASUREMENT_ID}`;
  document.head.appendChild(adsScript);

  // Fetch IP address first
  let gtmEnabled = true;
  let userIp = '';
  try {
    console.log("Fetching IP address...");
    const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    userIp = data.ip;
    const yourIpAddress = "72.185.254.127";
    // gtmEnabled = userIp !== yourIpAddress;
    console.log("IP address fetched successfully:", userIp);
    
    // Initialize dataLayer and gtag function
    window.dataLayer = window.dataLayer || [];
    function gtag() { window.dataLayer.push(arguments); }
    gtag('js', new Date());

    // Set user ID and configuration for GA4
    gtag('set', 'user_id', 'inmain');
    gtag('config', GA4_MEASUREMENT_ID, {
      'user_id': 'inmain',
      'user_properties': {
        'user_ip': userIp
      }
    });

    // Configure Google Ads with user ID
    gtag('config', ADS_MEASUREMENT_ID, {
      'user_id': 'inmain'
    });

    // Send IP capture event
    gtag('event', 'ip_captured', {
      'user_ip': userIp
    });

    window.dataLayer.push({
      'user_id': 'inmain',
    });
    window.dataLayer.push({
      'userId': 'inmain',
    });
    window.dataLayer.push({
      'user_ip': userIp,
      'event': 'ip_captured'
    });
    // Add gtag config command for user_id
    window.dataLayer.push({
      'gtm.start': new Date().getTime(),
      'event': 'gtm.js',
      'config': {
        'user_id': 'inmain'
      }
    });

  } catch (error) {
    console.error("Failed to fetch IP:", error);
  }

  // Configure GTM
  app.use(
    createGtm({
      id: "GTM-KHV4B8TG", // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
      // id: ["GTM-KHV4B8TG",'AW-16523079416'], // Your GTM single container ID, array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy'] or array of objects [{id: 'GTM-xxxxxx', queryParams: { gtm_auth: 'abc123', gtm_preview: 'env-4', gtm_cookies_win: 'x'}}, {id: 'GTM-yyyyyy', queryParams: {gtm_auth: 'abc234', gtm_preview: 'env-5', gtm_cookies_win: 'x'}}], // Your GTM single container ID or array of container ids ['GTM-xxxxxx', 'GTM-yyyyyy']
      //   queryParams: {
      //     // Add URL query string when loading gtm.js with GTM ID (required when using custom environments)
      //     gtm_auth: 'AB7cDEf3GHIjkl-MnOP8qr',
      //     gtm_preview: 'env-4',
      //     gtm_cookies_win: 'x',
      //   },
      //   source: 'https://customurl.com/gtm.js', // Add your own serverside GTM script
      defer: false, // Script can be set to `defer` to speed up page load at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible). Defaults to false, so the script is loaded `async` by default
      compatibility: true, // Will add `async` and `defer` to the script tag to not block requests for old browsers that do not support `async`
      // nonce: "2726c7f26c", // Will add `nonce` to the script tag
      enabled: gtmEnabled, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
      debug: true, // Whether or not display console logs debugs (optional)
      // loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
      vueRouter: router, // Pass the router instance to automatically sync with router (optional)
      ignoredViews: ["Dev"], // Don't trigger events for specified router names (optional)
      trackOnNextTick: true, // Whether or not call trackView in Vue.nextTick
    })
  );

  const pinia = createPinia();
  pinia.use(piniaPluginPersistedstate);
  app.use(pinia);
  app.use(router);

  // Toast options are optional
  const options = {
    position: "top-right",
    timeout: 3000,
    closeOnClick: true,
    pauseOnFocusLoss: true,
    pauseOnHover: true,
    draggable: true,
    draggablePercent: 0.6,
    showCloseButtonOnHover: false,
    hideProgressBar: true,
    closeButton: "button",
    icon: true,
    rtl: false,
  };

  app.use(Toast, options);
  app.mount("#app");
}

// Initialize the app
initializeApp();
