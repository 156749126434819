<template>
    <div class="suggestion-container">
      <button 
        class="suggestion-button clickable-button" 
        @click="toggleForm" 
        v-if="!showForm"
      >
        <i class="fas fa-lightbulb"></i> Make a Suggestion
      </button>
  
      <div v-if="showForm" class="suggestion-form">
        <div class="form-content">
          <button class="close-button" @click="toggleForm">&times;</button>
          <h3>Make a Suggestion</h3>
          <textarea 
            v-model="suggestion" 
            placeholder="Tell us your idea for improving betBOSS.ai..."
            rows="4"
          ></textarea>
          <button 
            class="submit-button clickable-button" 
            @click="submitSuggestion"
            :disabled="isSubmitting"
          >
            <span v-if="isSubmitting">
              <i class="fas fa-spinner fa-spin"></i> Sending...
            </span>
            <span v-else>Submit Suggestion</span>
          </button>
        </div>
      </div>
    </div>
  </template>
  
  <script setup lang="ts">
  import { ref } from 'vue';
  import axios from 'axios';
  import { useStore } from '../stores/store';
  import { useToast } from 'vue-toastification';
  
  const store = useStore();
  const toast = useToast();
  
  const showForm = ref(false);
  const suggestion = ref('');
  const isSubmitting = ref(false);
  
  const toggleForm = () => {
    showForm.value = !showForm.value;
    if (!showForm.value) {
      suggestion.value = '';
    }
  };
  
  const submitSuggestion = async () => {
    if (!suggestion.value.trim()) {
      toast.warning('Please enter a suggestion');
      return;
    }
  
    isSubmitting.value = true;
  
    try {
      await axios.post('https://back.betboss.ai/suggestion', {
        suggestion: suggestion.value,
        email: store.userEmail || 'anonymous',
        ip_address: store.userIp
      });
  
      toast.success('Thank you for your suggestion!');
      suggestion.value = '';
      showForm.value = false;
    } catch (error) {
      toast.error('Failed to submit suggestion. Please try again.');
      console.error('Error submitting suggestion:', error);
    } finally {
      isSubmitting.value = false;
    }
  };
  </script>
  
  <style scoped>
  .suggestion-container {
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
  }
  
  .suggestion-button {
    background-color: #4E9F3D;
    padding: 10px 20px;
    border-radius: 25px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  }
  
  .suggestion-form {
    position: fixed;
    bottom: 20px;
    right: 20px;
    background-color: #333;
    border-radius: 12px;
    padding: 20px;
    width: 300px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    border: 1px solid #4E9F3D;
  }
  
  .form-content {
    position: relative;
  }
  
  .close-button {
    position: absolute;
    top: -15px;
    right: -15px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    color: #4E9F3D;
    padding: 5px 10px;
  }
  
  .close-button:hover {
    color: #fff;
  }
  
  textarea {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border-radius: 8px;
    background-color: #242424;
    color: #fff;
    border: 1px solid #4E9F3D;
    resize: vertical;
    font-family: inherit;
  }
  
  textarea:focus {
    outline: none;
    border-color: #0ce72a;
  }
  
  .submit-button {
    width: 100%;
    margin-top: 10px;
  }
  
  h3 {
    color: #4E9F3D;
    margin: 0 0 15px 0;
  }
  </style>