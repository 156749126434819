import { createRouter, createWebHistory } from "vue-router";
import Home from "../components/Home.vue";
import Stripe from "../components/Stripe.vue";
import Odds from "../components/Odds.vue";
import ReviewGrabber from "../components/ReviewGrabber.vue";
import Dev from "../components/Dev.vue";
import ThankYou from "../components/ThankYou.vue";
import Reset from "../components/Reset.vue"; 
import GamesScrape from "../components/GamesScrape.vue";
const routes = [
  { path: "/", name: "Home", component: Home },
  { path: "/stripe", name: "Stripe", component: Stripe },
  { path: "/odds", name: "Odds", component: Odds },
  { path: "/review-grabber", name: "ReviewGrabber", component: ReviewGrabber },
  { path: "/dev", name: "Dev", component: Dev },
  { path: "/thank_you", name: "ThankYou", component: ThankYou },
  // { path: "/tank_you", name: "ThankYou", component: ThankYou },
  { path: "/reset", name: "Reset", component: Reset }, // Add the new route
  { path: "/scrape", name: "GamesScrape", component: GamesScrape }, // Add the new route
  { 
    path: "/register", 
    name: "Register",
    component: Home, // Use any component, it won't be visible
    beforeEnter: (to, from, next) => {
      // This will be captured by your Android app's Firebase analytics
      next();
      // After the route is entered, redirect
      window.location.href = "https://auth.betboss.ai/register";
    }
  },
  // Move the catch-all route to the end
  { path: "/:catchAll(.*)*", redirect: "/" },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

export default router;
