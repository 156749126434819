<template>
  <div class="container">
    <!-- <h1>streaming review getter</h1> -->
    <div :class="['video-container', { 'captured': imageCaptured }]">
      <video ref="video" autoplay></video>
      <img v-if="imageCaptured" :src="capturedImageUrl" alt="Captured Image" class="captured-image" />
    </div>
    <div class="buttons">
      <button @click="captureImage" class="btn capture-btn">
        <i class="fas fa-camera"></i> Capture
      </button>
      <button @click="sendImage" :disabled="!imageCaptured" class="btn send-btn">
        <i class="fas fa-paper-plane"></i> Analyze
      </button>
      <button @click="flipCamera" class="btn flip-btn">
        <i class="fas fa-sync-alt"></i>  Flip Camera
      </button>
      <!-- <button @click="downloadImage" :disabled="!imageCaptured" class="btn download-btn">
        <i class="fas fa-download"></i> 
      </button> -->
    </div>
    <div :class="['scores', { 'show-scores': showScores }]" v-if="showScores">
  <h2>Show Scores</h2>
  <table class="score-table">
    <thead>
      <tr>
        <th>Show Name</th>
        <th>RT AI</th>
        <th>IMDb AI</th>
        <th>RT Scraped TV</th>
        <th>RT Scraped Movie</th>
      </tr>
    </thead>
    <tbody>
      <tr v-for="score in showScores" :key="score.show_name">
        <td>{{ score.show_name }}</td>
        <td>{{ score.rotten_tomatoes_score }}</td>
        <td>{{ score.imdb_score }}</td>
        <td>{{ score.rotten_tomatoes_score_tvSeries }}</td>
        <td>{{ score.rotten_tomatoes_score_movie }}</td>
      </tr>
    </tbody>
  </table>
  <!-- <ul>
    <li v-for="score in showScores" :key="score.show_name">
      {{ score.show_name }} - RT AI: {{ score.rotten_tomatoes_score }}, IMDb AI: {{ score.imdb_score }}, RT Scraped TV: {{ score.rotten_tomatoes_score_tvSeries }}, RT Scraped Movie: {{ score.rotten_tomatoes_score_movie }}
    </li>
  </ul> -->
</div>
    <div class="instructions">
      <ol>
        <li>Snap image of tiles on any streaming service</li>
        <li>Click send</li>
        <li>Get reviews</li>
        <li>Reload to start over</li>
      </ol>
    </div>
    <img src="../assets/netflix.png" alt="Streaming Service" class="streaming-image" />
    <canvas ref="canvas" style="display: none;"></canvas>
    <div v-if="loading" class="loading-overlay">
      <div class="loading-spinner"></div>
    </div>

  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';

const imageCaptured = ref(false);
const capturedImageUrl = ref('');
const showScores = ref(null);
const loading = ref(false);

const video = ref(null);
const canvas = ref(null);
const currentCamera = ref('environment');
const testModal = () => {
  loading.value = true;
  setTimeout(() => {
    loading.value = false;
  }, 3000);
};
const setupCamera = () => {
  navigator.mediaDevices.getUserMedia({ 
    video: { 
      facingMode: currentCamera.value,
      width: { ideal: 1280 },
      height: { ideal: 720 }
    } 
  })
  .then(stream => {
    video.value.srcObject = stream;
  })
  .catch(err => {
    console.error("Error accessing camera: ", err);
  });
};
const captureImage = () => {
  const videoElement = video.value;
  const canvasElement = canvas.value;
  const context = canvasElement.getContext('2d');

  // Set canvas size to match video size
  canvasElement.width = videoElement.videoWidth;
  canvasElement.height = videoElement.videoHeight;

  context.drawImage(videoElement, 0, 0, canvasElement.width, canvasElement.height);

  // Calculate the new dimensions while maintaining aspect ratio
  let newWidth = canvasElement.width;
  let newHeight = canvasElement.height;
  const maxDimension = 1000;

  if (newWidth > newHeight && newWidth > maxDimension) {
    newHeight = (newHeight / newWidth) * maxDimension;
    newWidth = maxDimension;
  } else if (newHeight > newWidth && newHeight > maxDimension) {
    newWidth = (newWidth / newHeight) * maxDimension;
    newHeight = maxDimension;
  } else if (newWidth === newHeight && newWidth > maxDimension) {
    newWidth = maxDimension;
    newHeight = maxDimension;
  }

  // Create a new canvas to resize the image
  const resizedCanvas = document.createElement('canvas');
  resizedCanvas.width = newWidth;
  resizedCanvas.height = newHeight;
  const resizedContext = resizedCanvas.getContext('2d');
  resizedContext.drawImage(canvasElement, 0, 0, newWidth, newHeight);

  // Add text overlay
  resizedContext.font = '30px Arial';
  resizedContext.fillStyle = 'blue'; // Set text color to blue
  resizedContext.textAlign = 'center';
  resizedContext.fillText('Captured, now send for analysis', newWidth / 2, newHeight - 30);

  imageCaptured.value = true;
  capturedImageUrl.value = resizedCanvas.toDataURL('image/jpeg');
};

const sendImage = async () => {
  loading.value = true;
  const imageBlob = await new Promise(resolve => canvas.value.toBlob(resolve, 'image/jpeg'));
  const formData = new FormData();
  formData.append('file', imageBlob, 'captured_image.jpg');

  try {
    const response = await fetch('https://back.betboss.ai/analyze-image/', {
      method: 'POST',
      body: formData,
    });
    if (!response.ok) {
      throw new Error('Failed to send image');
    }
    const data = await response.json();
    showScores.value = data.show_scores;
  } catch (error) {
    console.error("Error sending image: ", error);
  } finally {
    loading.value = false;
  }
};

const flipCamera = () => {
  currentCamera.value = currentCamera.value === 'user' ? 'environment' : 'user';
  setupCamera();
};

const downloadImage = () => {
  const link = document.createElement('a');
  link.href = capturedImageUrl.value;
  link.download = 'captured_image.jpg';
  link.click();
};

onMounted(() => {
  setupCamera();
});
</script>

<style scoped>
@import "@/assets/games.css";

.container {
  text-align: center;
  font-family: Arial, sans-serif;
  color: #E8E8E8;
  background-color: #333333;
  padding: 20px;
  border-radius: 10px;
  position: relative;
}

.video-container {
  position: relative;
  width: 100%;
  max-width: 640px;
  height: auto;
  margin: 0 auto;
  border: 2px solid #555555;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
}

.video-container.captured {
  border-color: blue;
}

video {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.captured-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  /* height: 100%; */
  opacity: 1;
  border-radius: 10px;
}

.buttons {
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.btn {
  padding: 10px 20px;
  margin: 5px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
  flex: 1 1 auto;
  max-width: 150px;
}

.capture-btn {
  background-color: #4c5eaf;
  color: white;
}

.send-btn {
  background-color: #2196F3;
  color: white;
}

.send-btn:disabled {
  background-color: #757575;
  cursor: not-allowed;
}

.flip-btn {
  background-color: #ff0080;
  color: white;
}

.instructions {
  margin-top: 20px;
  font-size: 16px;
  color: #E8E8E8;
}

.instructions ol {
  list-style-type: decimal;
  padding-left: 20px;
  margin: 0;
}

.instructions li {
  margin-bottom: 10px;
  text-align: left;
}

.streaming-image {
  margin-top: 20px;
  max-width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
}

.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loading-spinner {
  border: 16px solid #f3f3f3;
  border-top: 16px solid #3498db;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  margin: 20px auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.scores {
  margin-top: 20px;
  text-align: left;
  background-color: #444444;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.6);
  transition: margin-top 0.3s ease;
  position: relative; /* Add this line */
  z-index: 1; /* Default z-index */
}

.scores.show-scores {
  margin-top: -200px;
  z-index: 1000; /* High z-index */
}

.scores h2 {
  font-size: 24px;
  margin-bottom: 10px;
}

.score-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  color: #E8E8E8; /* Light text color for dark theme */
}

.score-table th, .score-table td {
  border: 1px solid #555555; /* Darker border for dark theme */
  padding: 8px;
  text-align: left;
}

.score-table th {
  background-color: #4CAF50; /* Keep the header background color */
  color: white;
}

.score-table tr:nth-child(even) {
  background-color: #444444; /* Darker background for even rows */
}

.score-table tr:nth-child(odd) {
  background-color: #333333; /* Darker background for odd rows */
}

.score-table tr:hover {
  background-color: #555555;
}

@media (max-width: 768px) {
  .container {
    padding: 10px;
  }

  .video-container {
    width: 100%;
    height: auto;
  }

  .btn {
    font-size: 14px;
    padding: 8px 16px;
  }

  .scores h2 {
    font-size: 20px;
  }

  .scores li {
    font-size: 14px;
  }
}
</style>
