<template>
  <div v-if="isOpen" class="modal-overlay" @click="closeModal">
    <div class="info-modal-content" @click.stop>
      <button class="close-button" @click="closeModal">×</button>
      
      <h2>Why do our picks WIN?</h2>
      
      <div class="content-section">
        <h3>🤖 20 Hours of Research in Seconds</h3>
        <p>While other services give you generic picks, our AI analyzes 100+ fresh articles, injury reports, and expert analyses for every game - work that would take you 20+ hours manually.</p>
      </div>

      <div class="content-section">
        <h3>📊 Real-Time Market Intelligence</h3>
        <p>Get instant alerts on line movements, injury updates, and betting trends. While others rely on day-old data, we process information 24/7 to spot value opportunities others miss.</p>
      </div>

      <div class="content-section">
        <h3>💡 Transparent AI Reasoning</h3>
        <p>Unlike "black box" picks services, we show you exactly why our AI makes each prediction. See the analyzed sources and key factors behind every recommendation.</p>
      </div>

      <div class="content-section">
        <h3>💰 Try Before You Buy</h3>
        <p>Start with 3 FREE game analyses today. Then get 100 searches per week for just $20 - that's cents per prediction for research that would cost hundreds elsewhere.</p>
      </div>

      <div class="content-section">
        <h3>⚡ Instant Analysis</h3>
        <p>Stop wasting hours reading articles and listening to podcasts. Get comprehensive game breakdowns in seconds with our cutting-edge AI transformer models.</p>
      </div>

      <div class="cta-section">
        <button class="action-button" @click="redirectToStripe">
          Get Winning Picks - Start Free Trial
        </button>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from 'vue';
import { useRouter } from 'vue-router';

const router = useRouter();
const props = defineProps<{
  isOpen: boolean
}>();

const emit = defineEmits(['close']);

const closeModal = () => {
  emit('close');
};

const redirectToStripe = () => {
  router.push('/stripe');
};
</script>

<style scoped>
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.info-modal-content {
  background-color: #333;
  padding: 2rem;
  border-radius: 12px;
  max-width: 600px;
  width: 90%;
  max-height: 90vh;
  overflow-y: auto;
  position: relative;
  border: 1px solid #4e9f3d;
  color: #fff;
  
  /* Custom Scrollbar Styles */
  scrollbar-width: thin; /* Firefox */
  scrollbar-color: #4e9f3d #333; /* Firefox */
}

/* Webkit (Chrome, Safari, Edge) scrollbar styles */
.info-modal-content::-webkit-scrollbar {
  width: 8px;
}

.info-modal-content::-webkit-scrollbar-track {
  background: #333;
  border-radius: 4px;
}

.info-modal-content::-webkit-scrollbar-thumb {
  background: #4e9f3d;
  border-radius: 4px;
  border: 2px solid #333;
}

.info-modal-content::-webkit-scrollbar-thumb:hover {
  background: #3d8030;
}

.close-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #4e9f3d;
  padding: 5px 10px;
}

.close-button:hover {
  color: #fff;
}

h2 {
  color: #4e9f3d;
  margin-bottom: 1.5rem;
  text-align: center;
}

.content-section {
  margin-bottom: 1.5rem;
  padding: 1rem;
  background-color: #242424;
  border-radius: 8px;
  border: 1px solid #4e9f3d;
}

h3 {
  color: #4e9f3d;
  margin-bottom: 0.5rem;
}

p {
  color: #d0e2cd;
  line-height: 1.6;
}

@media (max-width: 600px) {
  .info-modal-content {
    width: 95%;
    padding: 1rem;
  }
}

.cta-section {
  text-align: center;
  margin-top: 2rem;
  position: sticky;
  bottom: 1rem;
  background-color: #333;
  padding: 1rem;
  border-top: 1px solid #4e9f3d;
}

.action-button {
  position: relative;
  background-color: #4E9F3D;
  color: white;
  padding: 0.6em 1.2em;
  font-size: 1.2rem;
  border-radius: 8px;
  border: 2px solid #1E5128;
  cursor: pointer;
  overflow: hidden;
  width: 80%;
  max-width: 400px;
  text-transform: uppercase;
  font-weight: bold;
  transition: all 0.2s ease;
  letter-spacing: 0.5px;
}

/* Shine effect */
.action-button::before {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(45deg, transparent, rgba(216, 233, 168, 0.5), transparent);
  transform: rotate(45deg);
  animation: shine 2s infinite linear;
}

@keyframes shine {
  from {
    transform: rotate(45deg) translateX(-100%);
  }
  to {
    transform: rotate(45deg) translateX(200%);
  }
}

.action-button:hover {
  background-color: #1E5128;
  color: #FFFFFF;
  border-color: #d0e2cd;
  transform: translateY(-2px);
  box-shadow: 0 6px 8px rgba(0, 0, 0, 0.2);
}

.action-button:active {
  transform: translateY(2px);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.2);
}
</style> 
