<template>
  <div class="market-container">
    <div class="market-header">
      <div class="header-item">Team</div>
      <div class="header-item">Spread</div>
      <div class="header-item">Totals</div>
      <div class="header-item">Moneyline</div>
    </div>
    <div class="market-body">
      <!-- Home Team Row -->
      <div class="body-row team-info">
        <div class="row-item">
          <div class="team-logo-name">
            <div class="logo-container"><img :src="`team_logos/${store.selectedLeague}/${game.home_team}.png`"
                class="team-logo" 
                @error="event => event.target.src = 'https://placehold.co/20x20/blue/white'" /></div>
            <div class="team-name-container"><span class="team-name">{{ getTeamAbbreviation(store.selectedLeague,
              game.home_team) }}</span></div>
          </div>
        </div>
        <div class="row-item" v-html="findOdd('spreads', game.home_team)"></div>
        <div class="row-item" v-html="findOdd('totals', 'Over')"></div>
        <div class="row-item" v-html="findOdd('h2h', game.home_team)"></div>
      </div>
      <!-- Away Team Row -->
      <div class="body-row team-info">
        <div class="row-item">
          <div class="team-logo-name">
            <div class="logo-container"><img :src="`team_logos/${store.selectedLeague}/${game.away_team}.png`"
                class="team-logo"
                @error="event => event.target.src = 'https://placehold.co/20x20/red/white'" /></div>
            <div class="team-name-container"><span class="team-name">{{ getTeamAbbreviation(store.selectedLeague,
              game.away_team) }}</span></div>
          </div>
        </div>
        <div class="row-item" v-html="findOdd('spreads', game.away_team)"></div>
        <div class="row-item" v-html="findOdd('totals', 'Under')"></div>
        <div class="row-item" v-html="findOdd('h2h', game.away_team)"></div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps } from 'vue';
import { useMapsStore } from '../stores/maps';
import { useStore } from '../stores/store';
const props = defineProps({
  game: Object,
});

const mapsStore = useMapsStore();
const store = useStore();

const getTeamAbbreviation = (league, teamName) => {
  return mapsStore.getAbbreviation(league, teamName);
};

const findOdd = (type, name) => {
  // Check if the odds type exists before trying to find a specific odd
  if (!props.game.odds[type]) return 'N/A';
  const odd = props.game.odds[type].find(o => o.name.includes(name));
  if (!odd) return 'N/A';
  // Determine the prefix based on whether it's Over or Under
  const prefix = name === 'Over' ? 'O ' : name === 'Under' ? 'U ' : '';
  // Include the prediction in the returned string and make odd.point bold with the prefix
  return `${odd.prediction ? odd.prediction + ' ' : ''}${odd.point !== undefined ? '<strong>' + prefix + odd.point + '</strong> <br/>' : ''}${odd.price}`;
};
</script>

<style scoped>
.market-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: #242424;
  color: rgba(255, 255, 255, 0.87);
}

.market-header,
.body-row {
  display: flex;
}

.header-item,
.row-item {
  flex: 1;
  text-align: center;
  border: 1px solid #555;
  padding: 8px;
}

.team-logo-name {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 100%; 
}


.logo-container,
.team-name-container {
  display: flex;
  align-items: center;
}


.team-logo {
  height: 20px;
  width: auto;
  /* Allows the width to adjust based on the height while maintaining aspect ratio */
}


.team-name {
  text-align: left;
}

.logo-container {
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  /* justify-content: center; */
  min-width: 35px;
  /* Ensures a minimum width for logos */
}


.team-name-container {
  flex-grow: 1;
  display: flex;
  align-items: center;
  text-align: left;
}


</style>