import { defineStore } from "pinia";
import axios from "axios";
import { useRouter } from "vue-router";
export const useStore = defineStore("store", {
  state: () => ({
    searchCount: 0,
    allowedSearches: 3,
    userTier: "",
    leagueOdds: {},
    selectedLeague: "basketball_nba",
    isCustomMatchupVisible: false,
    isLoggedIn: false,
    userEmail: "",
    customAnalysisLoading: false,
    userIp: null,
    showStripeOverlay: false,
  }),

  getters: {
    leagueDisplayName: (state) => {
      const leagueMap = {
        americanfootball_nfl: "NFL",
        baseball_mlb: "MLB",
        icehockey_nhl: "NHL",
        basketball_nba: "NBA",
        soccer_uefa_european_championship: "UEFA Cup",
        soccer_uefa_nations_league: "UEFA Nations",
        // Add more mappings as needed
      };
      return leagueMap[state.selectedLeague] || state.selectedLeague;
    },
  },

  actions: {
    setSelectedLeague(leagueId) {
      this.selectedLeague = leagueId;
    },
    toggleCustomMatchupVisibility() {
      this.isCustomMatchupVisible = !this.isCustomMatchupVisible;
    },
    getAllowedSearchesByTier(tier: string): number {
      switch (tier) {
        case "rookie":
          return 300;
        case "pro":
          return 800;
        case "elite":
          return 2000;
        case "free":
          return 10;
        default:
          return 3;
      }
    },
    setUserTier(tier: string) {
      this.userTier = tier;
      this.allowedSearches = this.getAllowedSearchesByTier(tier);
    },

    async checkLoginStatus() {
      console.log("Fetching user tier and calculating remaining searches...");
      try {
        if (!this.userIp) {
          await this.fetchUserIp();
        }

        const response = await axios.get("https://back.betboss.ai/check-login", {
          withCredentials: true,
          params: {
            ip_address: this.userIp,
          },
        });
        this.isLoggedIn = response.data.logged_in;

        if (response.data.rate_limit_count !== undefined) {
          this.searchCount = response.data.rate_limit_count;
        }

        if (this.isLoggedIn) {
          this.userEmail = response.data.user.email;
          if (this.userEmail) {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              'user_id': this.userEmail.split('@')[0],
              'userId': this.userEmail.split('@')[0]
            });
          }
          const tier = response.data.user.fields.tier || "";
          this.userTier = tier;
          this.allowedSearches = this.getAllowedSearchesByTier(tier);
          return;
        }

        // Reset state when user is not logged in
        this.userTier = "";
        this.userEmail = "";
        this.allowedSearches = this.getAllowedSearchesByTier("");
      } catch (error) {
        console.error("Error fetching user tier:", error);
        this.isLoggedIn = false;
        this.userTier = "";
        this.userEmail = "";
        this.allowedSearches = this.getAllowedSearchesByTier("");
      }
    },

    updateLeagueOdds(newOdds) {
      console.log("Updating league odds in the store...");
      this.leagueOdds = newOdds;
    },

    checkRateLimit() {
      if (this.searchCount >= this.allowedSearches) {
        return {
          shouldRedirect: true,
          isLoggedIn: this.isLoggedIn,
        };
      }
      return { shouldRedirect: false };
    },

    async fetchUserIp() {
      try {
        const response = await fetch("https://api.ipify.org?format=json");
        const data = await response.json();
        this.userIp = data.ip;
      } catch (error) {
        console.error("Failed to fetch IP:", error);
      }
    },

    updateSearchCount(picksUsed: number) {
      this.searchCount = picksUsed;
    },
  },

  persist: {
    beforeHydrate: (ctx) => {
      console.log("Before hydrate");
      // console.log(`About to hydrate store '${ctx.store.$id}'`);
    },
    afterHydrate: (ctx) => {
      console.log("After hydrate");
      // console.log(`Successfully hydrated store '${ctx.store.$id}'`);
    },
    debug: true,
  },
});
