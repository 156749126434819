<template>
    <div class="confidence-slider-container" v-if="showSlider">
      <div v-if="!hasVoted">
        <div class="confidence-label">
          How much do you agree with this pick?
        </div>
        <div class="slider-wrapper">
          <input
            type="range"
            v-model="confidence"
            min="0"
            max="100"
            class="confidence-slider"
            @change="submitConfidence"
          >
          <div class="slider-labels">
            <span>Disagree</span>
            <span>Neutral</span>
            <span>Agree</span>
          </div>
        </div>
      </div>

      <div v-else class="confidence-stats">
        <div class="stats-header">
          Community Confidence (10{{ confidenceStats.total_votes  }} votes)
        </div>
        <div class="stats-bars">
          <div class="stat-bar">
            <div class="bar-container">
              <div class="bar negative" :style="{ width: confidenceStats.negative_percentage + '%' }"></div>
              <div class="bar positive" :style="{ width: confidenceStats.positive_percentage + '%' }"></div>
            </div>
            <div class="percentage-labels">
              <span class="negative">{{ confidenceStats.negative_percentage }}% Disagree</span>
              <span class="positive">{{ confidenceStats.positive_percentage }}% Agree</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup lang="ts">
  import { ref, computed, onMounted } from 'vue';
  import axios from 'axios';
  import { useToast } from 'vue-toastification';
  import { useStore } from '../stores/store';
  import { useGtm } from "@gtm-support/vue-gtm";
  
  const props = defineProps<{
    gameId: string
  }>();
  
  const store = useStore();
  const toast = useToast();
  const gtm = useGtm();
  const trackEvent = gtm.trackEvent.bind(gtm);
  const confidence = ref(50);
  const showSlider = computed(() => props.gameId !== undefined);
  const hasVoted = ref(false);
  
  const confidenceStats = ref({
    total_votes: 0,
    positive_percentage: 0,
    negative_percentage: 0
  });
  
  const fetchConfidenceStats = async () => {
    try {
      const response = await axios.get(`https://back.betboss.ai/pick-confidence/${props.gameId}`);
      confidenceStats.value = response.data;
    } catch (error) {
      console.error('Error fetching confidence stats:', error);
    }
  };
  
  const submitConfidence = async () => {
    try {
      await axios.post('https://back.betboss.ai/pick-confidence', null, {
        params: {
          game_id: props.gameId,
          confidence_level: confidence.value / 100,
          ip_address: store.userIp
        }
      });
  
      trackEvent({
        event: "pick_confidence",
        category: "user_interaction",
        action: "submit_confidence",
        label: `confidence_level_${confidence.value}`,
        event_value: confidence.value,
        noninteraction: false,
      });
  
      toast.success('Thanks for your vote!');
      await fetchConfidenceStats();
      hasVoted.value = true;
    } catch (error) {
      toast.error('Failed to submit feedback');
      console.error('Error submitting confidence:', error);
    }
  };
  
  onMounted(() => {
    fetchConfidenceStats();
  });
  </script>
  
  <style scoped>
  .confidence-slider-container {
    margin: 0.5rem 0;
    padding: 1rem;
    background: #333;
    border-radius: 8px;
    border: 1px solid #444;
  }
  
  .confidence-label {
    margin-bottom: 0.5rem;
    font-size: 0.9rem;
    color: #d0e2cd;
  }
  
  .slider-wrapper {
    width: 100%;
    margin-bottom: 0.5rem;
  }
  
  .confidence-slider {
    width: 100%;
    height: 8px;
    -webkit-appearance: none;
    background: linear-gradient(to right, #ff4444, #ffeb3b, #4CAF50);
    border-radius: 4px;
    outline: none;
  }
  
  .confidence-slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    width: 20px;
    height: 20px;
    background: #4e9f3d;
    border: 2px solid #1E5128;
    border-radius: 50%;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .confidence-slider::-webkit-slider-thumb:hover {
    background: #1E5128;
    border-color: #d0e2cd;
    box-shadow: 0 0 5px rgba(78, 159, 61, 0.5);
  }
  
  .slider-labels {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    font-size: 0.8rem;
    color: #d0e2cd;
  }
  
  .confidence-stats {
    margin: 0.5rem 0;
  }
  
  .stats-header {
    font-size: 0.9rem;
    color: #d0e2cd;
    margin-bottom: 1rem;
    text-align: center;
  }
  
  .stats-bars {
    margin: 0.5rem 0;
  }
  
  .stat-bar {
    width: 100%;
  }
  
  .bar-container {
    height: 8px;
    background: #444;
    border-radius: 4px;
    overflow: hidden;
    display: flex;
  }
  
  .bar {
    height: 100%;
    transition: width 0.3s ease;
  }
  
  .bar.positive {
    background: #4CAF50;
  }
  
  .bar.negative {
    background: #ff4444;
  }
  
  .percentage-labels {
    display: flex;
    justify-content: space-between;
    margin-top: 0.5rem;
    font-size: 0.8rem;
    color: #d0e2cd;
  }
  
  .percentage-labels .positive {
    color: #4CAF50;
  }
  
  .percentage-labels .negative {
    color: #ff4444;
  }
  </style>
