<template>
  <footer class="footer">
    <div class="container">
      <p>
        <!-- &copy; {{ new Date().getFullYear() }} -  -->
      <a href="mailto:cs@betboss.ai">cs@betboss.ai</a></p>
    </div>
  </footer>
</template>

<style scoped>
.footer {
  text-align: center;
  /* padding: 20px; */
  background-color: #333; /* Updated to match games.css theme */
  color: #d0e2cd; /* Updated to match games.css text color */
  /* margin-top: 20px; */
}

a {
  color: #0ce72a; /* Updated to match style.css a color */
  text-decoration: none;
}
a:hover {
  color: #ffffff; /* Updated to match style.css a:hover color */
  text-decoration: underline;
}
</style>

