<template>
  <div>
    <h1>THIS Is DEV PAgedqqrr4</h1>
    <div>Your IP Address: {{ ipAddress }}</div>
    <button class="track-button" @click="handleAllEvents">Track All Events</button>
  </div>
</template>

<script setup lang="ts">
import { ref, onMounted } from "vue";
import axios from "axios";
import { useStore } from "../stores/store";
import { useGtm } from "@gtm-support/vue-gtm";
const store = useStore();
const gtm = useGtm();

const ipAddress = ref('Loading...');

const GA4_MEASUREMENT_ID = 'G-0J44QSWQV4';
const ADS_MEASUREMENT_ID = 'AW-16523079416';
const USER_ID = 'dumbguy'; // Centralized user ID

// Function to set user ID for GA4 and Ads
const setUserID = () => {
  window.dataLayer = window.dataLayer || [];
  function gtag(){window.dataLayer.push(arguments);}
  
  // Set user ID for GA4
  gtag('set', 'user_id', USER_ID);
  
  // Configure GA4 with user ID
  gtag('config', GA4_MEASUREMENT_ID, {
    'user_id': USER_ID,
    'user_properties': {
      'user_tier': 'Pro',
      'user_email': 'USA'
    }
  });

  // Configure Google Ads with user ID
  gtag('config', ADS_MEASUREMENT_ID, {
    'user_id': USER_ID
  });

  // Set user ID in GTM
  gtm?.push({
    'userId': USER_ID,
    'set': {
      'userId': USER_ID,
      user_properties: {
        user_tier: 'Pro',
        user_email: 'USA'
      }
    }
  });
};

const handleAllEvents = async () => {
  try {
    const response = await axios.get('https://api.ipify.org?format=json');
    ipAddress.value = response.data.ip;

    // Initialize GA4
    window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());

    // Set user ID for all tracking
    setUserID();

  } catch (error) {
    console.error('Error fetching IP address:', error);
    ipAddress.value = 'Failed to load IP';
  }
};

onMounted(() => {
  // Add GA4 script
  const ga4Script = document.createElement('script');
  ga4Script.async = true;
  ga4Script.src = `https://www.googletagmanager.com/gtag/js?id=${GA4_MEASUREMENT_ID}`;
  document.head.appendChild(ga4Script);

  // Add Google Ads script
  const adsScript = document.createElement('script');
  adsScript.async = true;
  adsScript.src = `https://www.googletagmanager.com/gtag/js?id=${ADS_MEASUREMENT_ID}`;
  document.head.appendChild(adsScript);

  // Initialize tracking with user ID
  handleAllEvents();
});
</script>

<style scoped>
.track-button {
  margin-top: 20px;
  margin-right: 10px;
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
}

.track-button:hover {
  background-color: #45a049;
}
</style>