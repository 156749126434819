<template>
  <div class="checkout-container">
    <TopBar />
    
    <div v-if="loading" class="loading-state">
      <div class="loader">Loading...</div>
    </div>

    <div v-else-if="hasSubscription" class="message-container">
      <h2>Account already created for {{ userEmail }}. <a href="https://back.betboss.ai/protected">Login here</a></h2>
    </div>

    <div v-else class="pricing-container">
      <!-- Header Section -->
      <div class="pricing-header">
        <h1>Invest in your bankroll</h1>
        <h2>Upgrade now to unlock more winning picks!</h2>
        <div v-if="store.searchCount > -1" class="usage-stats">
          <span>You have used {{ store.searchCount }} of {{ store.allowedSearches }} weekly picks</span>
        </div>
      </div>

      <!-- Pricing Cards -->
      <div class="pricing-cards">
        <div v-for="button in stripeButtons" :key="button.id" class="pricing-card">
          <div class="plan-header">
            <h3>{{ button.name }}</h3>
            <p class="picks-count">Get {{ button.picks }} picks/week!</p>
          </div>
          <stripe-buy-button 
            :buy-button-id="button.id" 
            :publishable-key="publishableKey" 
            :client-reference-id="clientReferenceId"
            @click="handleSubscriptionClick(button.name)"
          />
        </div>
      </div>
    </div>

    <Footer />
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import TopBar from "./TopBar.vue";
import Footer from "./Footer.vue";
import { storeToRefs } from "pinia";
import { useStore } from "../stores/store";
import { useGtm } from "@gtm-support/vue-gtm";
import axios from 'axios';

const gtm = useGtm();
const trackEvent = gtm.trackEvent.bind(gtm);

const store = useStore();
const { userEmail } = storeToRefs(store);

const clientReferenceId = ref('');
const publishableKey = "pk_live_51OlbxxCwPAlJePWckrBDCzu7rNypjkdmITTtwNz7z2Y2fTav6i8TXAYOtzECVMPaODKjbRNDU00O6VvtOlzwfPQN00KVmzRTmO";
const stripeButtons = [
  { id: "buy_btn_1PYtvvCwPAlJePWclTe1MR2E", name: "Rookie", picks: 300 },
  { id: "buy_btn_1PYtyfCwPAlJePWc9csHOkOw", name: "Pro", picks: 800 },
  { id: "buy_btn_1PYtOLCwPAlJePWc6CmOGdtY", name: "Elite", picks: 2000 },
];

const loading = ref(true);
const hasSubscription = ref(false);

const handleSubscriptionClick = (planName: string) => {
  trackEvent({
    event: "subscription_click",
    category: "ecommerce",
    action: "select_plan",
    label: planName.toLowerCase(),
    noninteraction: false
  });
};

onMounted(async () => {
  // Load Stripe script
  const stripeScript = document.createElement('script');
  stripeScript.src = 'https://js.stripe.com/v3/buy-button.js';
  stripeScript.async = true;
  document.head.appendChild(stripeScript);

  // Check for existing STRIPE subscriptions
  try {
    if (userEmail.value) {
      const response = await axios.get(`https://back.betboss.ai/customer/${encodeURIComponent(userEmail.value)}`);
      
      // Check if user has a subscription
      if (response.data.has_subscription && response.data.customer_info?.subscription) {
        const subscription = response.data.customer_info.subscription;
        hasSubscription.value = subscription.status === 'trialing';
        
        if (hasSubscription.value) {
          const tier = subscription.plan_name.toLowerCase();
          store.setUserTier(tier);
          store.searchCount = 0;
          
          try {
            // Delete rate limit records for the user's IP
            await axios.delete(`https://back.betboss.ai/delete-rate-limit/${store.userIp}`);
            
            const customerResponse = await axios.get(
              `https://back.betboss.ai/get-customer-id-by-email/${encodeURIComponent(userEmail.value?.trim().replace(/['"]/g, ''))}`
            );
            if (customerResponse.data && customerResponse.data.tier) {
              store.setUserTier(customerResponse.data.tier);
            }
          } catch (error) {
            console.error('Error in subscription setup:', error);
          }
        }
      } else {
        hasSubscription.value = false;
      }
    }
  } catch (error) {
    // Log error but don't break the UI
    console.warn('Error checking subscriptions:', error);
    hasSubscription.value = false;
  } finally {
    loading.value = false;
  }

  // Watch for Stripe iframe creation and message events
  window.addEventListener('message', function(event) {
    // Only handle messages from Stripe domains
    if (event.origin.includes('stripe.com')) {
      try {
        const data = event.data;
        // Check if this is a successful purchase event
        if (data.type === 'buy-button:success') {
          // Handle successful purchase
          console.log('Purchase successful!', data);
          // Fire your tracking events here
        }
      } catch (e) {
        console.error('Error handling Stripe message:', e);
      }
    }
  });
});
</script>

<style scoped>
.checkout-container {
  min-height: 100vh;
  /* background-color: #1a1a1a; */
  color: #ffffff;
}

.pricing-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem;
}

.pricing-header {
  text-align: center;
  margin-bottom: 3rem;
}

.pricing-header h1 {
  font-size: 2.5rem;
  color: #ffffff;
  margin-bottom: 1rem;
}

.pricing-header h2 {
  font-size: 1.8rem;
  color: #cccccc;
  margin-bottom: 1.5rem;
}

.usage-stats {
  background-color: rgba(255, 255, 255, 0.1);
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  display: inline-block;
  color: #ffffff;
}

.pricing-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  margin-bottom: 3rem;
}

.pricing-card {
  background: #2a2a2a;
  border-radius: 12px;
  padding: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s, box-shadow 0.2s;
  border: 1px solid rgba(255, 255, 255, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.pricing-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.3);
  border-color: rgba(255, 255, 255, 0.2);
}

.plan-header {
  text-align: center;
  margin-bottom: 1.5rem;
}

.plan-header h3 {
  font-size: 1.8rem;
  color: #ffffff;
  margin-bottom: 0.5rem;
}

.picks-count {
  font-size: 1.2rem;
  color: #cccccc;
  margin-bottom: 1rem;
}

.payment-methods {
  text-align: center;
  margin-top: 3rem;
  color: #cccccc;
  width: 100%;
}

.payment-icons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 1rem;
  opacity: 0.8;
}

/* Stripe button customization */
:deep(.stripe-buy-button) {
  width: 100% !important;
  display: flex !important;
  justify-content: center !important;
  background: transparent !important;
}

:deep(.stripe-buy-button iframe) {
  width: 100% !important;
  min-width: 0 !important;
  margin: 0 auto !important;
  max-width: 320px !important;
}

/* Loading state */
.loading-state {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 400px;
  color: #ffffff;
}

/* Message container */
.message-container {
  text-align: center;
  padding: 2rem;
  color: #ffffff;
}

.message-container a {
  color: #4CAF50;
  text-decoration: none;
}

.message-container a:hover {
  text-decoration: underline;
}

@media (max-width: 768px) {
  .pricing-container {
    padding: 1rem;
  }

  .pricing-header h1 {
    font-size: 2rem;
  }

  .pricing-header h2 {
    font-size: 1.5rem;
  }

  .pricing-cards {
    grid-template-columns: 1fr;
  }
}

.supported-payment-methods {
  text-align: center;
  width: 100%;
  margin-top: 1rem;
}
</style>
